<template>
  <div class="justify_container">
    <div class="header center_item">
      <span>
        <img class="igames_icon" :src="require(`@/assets/images/icon_48.png`)"/>
        <span class="panel_name">{{panelName}}</span>
      </span>
    </div>
    <div class="panel_container">
      <div class="left_part parts">
        <CustomButton :name="'ADICIONAR NOVO'" :active="gameSettings.saveButtonActive" @ontap="addNewGame()" />
        <CustomInput :label="'Procurar'" :background="'var(--background0)'" @event="searchGame" :margin="'20px'"/>
        <div class="game_list custom_scroll">
          <div class="game_item" v-for="game in games" v-bind:key="game.id" @click="selectGame(game.id)">
            <div class="game_item_name"> {{getGameTitle(game)}}</div>
            <div class="game_item_name_info">
              <div>{{game.date.split(" ")[0].replaceAll("-","/")}}</div>
              <div><b-icon-eye class="eye_icon"/> {{game.views}}</div>
            </div>
            <div class="center_item"><div class="post_status" :class="{'post_status_published':game.published}"></div></div>
          </div>
          <div class="center_horizontal"><Loading :color="'#7d7d7d'" :size="'40px'" v-if="loadingGames" /></div>
          <CustomButton :name="'CARREGAR MAIS'" @click="getGames()" class="load_more" v-if="!loadingGames && nextPage <= pages && nextPage > 0 && !searchingGames"/>
        </div>
        <CustomButton :name="'CONFIGURAÇÕES'" @click="openConfigs()" />
      </div>

      <div class="middle_part parts">
        <div class="game_superior_part">
          <label for="upload_image" v-cloak @drop.prevent="uploadLogo" @dragover.prevent>
            <div class="game_logo" :style="{'background-image': `url('${getImage(getGameEdit().logo)}')`}">
              <div class="card_game_add_action delete_print_action" v-if="getGameEdit().logo != ''">
                <b-icon-arrow-repeat class="trash_icon" />
              </div>
              <div class="icon_with_label" v-if="getGameEdit().logo == '' && !gameSettings.uploadingLogo">
                <p>CAPA</p>
                <b-icon-plus-circle-dotted class="logo_icon" />
              </div>
              <Loading :color="'#7d7d7d'" :size="'40px'" v-if="gameSettings.uploadingLogo"/>
            </div>
          </label>
          <input type="file" accept="image/*" id="upload_image" ref="logoFile" hidden @change="uploadLogo()"/>
          <div class="align_self">
            <CustomInput v-model="gameEdit.langs[gameSettings.currentLanguage].title" :label="'Título'" :background="'var(--background0)'" :margin="'10px'"/>
            <CustomTextArea v-model="gameEdit.langs[gameSettings.currentLanguage].description" :label="'Descrição'" :background="'var(--background0)'" :height="'232px'"/>
          </div>
        </div>

        <div class="game_details_input">
          <CustomInput :label="'Ano'" v-model="gameEdit.langs[gameSettings.currentLanguage].year" :background="'var(--background0)'" :margin="'16.5px'" :small="true"/>
          <CustomInput :label="'Tamanho'" v-model="gameEdit.langs[gameSettings.currentLanguage].size" :background="'var(--background0)'" :margin="'16.5px'" :small="true"/>
          <CustomInput :label="'Idiomas'" v-model="gameEdit.langs[gameSettings.currentLanguage].game_lang" :background="'var(--background0)'" :margin="'16.5px'" :small="true"/>
          <!--
          <div class="game_lang_input_div">
            <CustomInput
              :label="'Linguagem - code'"
              v-model="gameEdit.langs[gameSettings.currentLanguage].game_lang"
              @focus="gameLangSuggestionVisible = true"
              @blur="gameLangSuggestionVisible = false"
              :background="'var(--background0)'"
              :margin="'16.5px'"
              :small="true"
            />
            <div class="game_lang_suggestion" v-if="gameLangSuggestionVisible">
              <div class="item_popup" @click="gameEdit.langs[gameSettings.currentLanguage].game_lang='pt'">
                português
              </div>
              <div class="item_popup" @click="gameEdit.langs[gameSettings.currentLanguage].game_lang='en'">
                inglês
              </div>
              <div class="item_popup" @click="gameEdit.langs[gameSettings.currentLanguage].game_lang='es'">
                espanhol
              </div>
            </div>
          </div>
          -->
        </div>

        <div class="grid_columns_2">
          <CustomInput v-model="gameEdit.langs[gameSettings.currentLanguage].download_url" :label="'Link de Download'" :margin="'16.5px'" :background="'var(--background0)'" :height="'232px'"/>
          <CustomInput v-model="gameEdit.langs[gameSettings.currentLanguage].download_url_premium" :label="'Link de Download Premium'" :margin="'16.5px'" :background="'var(--background0)'" :height="'232px'"/>
        </div>
        <div class="grid_columns_2">
          <CustomInput v-model="gameEdit.langs[gameSettings.currentLanguage].video" :label="'Link de video'" :margin="'16.5px'" :background="'var(--background0)'" :height="'232px'"/>
          <Selector :items="gameArcSupported" :selected="arcSelected" @change="changeGameArc"/>
        </div>
        <div class="game_prints horizontal_scroll">
          <div
          class="prints_image"
          v-for="(item, index) in gameEdit.langs[gameSettings.currentLanguage].prints"
          v-bind:key="'print_'+index"
          :style="{'background-image': `url('${getImage(getGameEdit().prints[index])}')`}">
            <div class="card_game_add_action delete_print_action">
              <b-icon-trash class="trash_icon" @click="deleteGamePrint(index)"/>
            </div>
            <Loading :color="'#7d7d7d'" :size="'40px'" v-if="gameSettings.uploadingPrints"/>
          </div>
          <label for="upload_prints" v-cloak @drop.prevent="uploadPrints" @dragover.prevent>
            <div class="icon_with_label add_prints">
              <p>PRINTS</p>
              <b-icon-plus-circle-dotted class="logo_icon" />
            </div>
          </label>
        </div>

        <input type="file" accept="image/*" multiple id="upload_prints" ref="printFiles" hidden @change="uploadPrints()"/>
      </div>

      <div class="right_part parts" >
        <CustomButton :name="gameEdit.id == 0 ? 'SALVAR' : 'ATUALIZAR'" :active="gameSettings.saveButtonActive" @ontap="saveGame()"/>

        <div class="config_item">
          <div class="card_item_name">PUBLICADO</div>
          <div class="center_item card_item_action"><CheckBox value="foo" :checked="gameEdit.published" @chg="changePublished"/></div>
        </div>

        <div class="config_item">
          <div class="card_item_name">NOTIFICAR</div>
          <div class="center_item card_item_action"><CheckBox value="foo" :checked="gameEdit.notify" @chg="changeNotify"/></div>
        </div>

        <div class="cards_game">
          <div class="card_game_header">
            <div class="card_game_name">
              Idiomas
            </div>
          </div>
          <div class="card_content card_content_langs">
            <div class="card_item pointer" :class="{item_selected:isThisLanguage(gameSettings.languages.pt)}" @click="changeGameLanguage(gameSettings.languages.pt)">
              <div class="card_item_name"><img class="flag_item" :src="require(`@/assets/images/brazil_flag.png`)"/> PORTUGUÊS</div>
              <div class="center_item"><div class="post_status" :class="{'post_status_published':langIsOk(gameSettings.languages.pt)}"></div></div>
            </div>
            <div class="card_item pointer" :class="{item_selected:isThisLanguage(gameSettings.languages.en)}" @click="changeGameLanguage(gameSettings.languages.en)">
              <div class="card_item_name"><img class="flag_item" :src="require(`@/assets/images/usa_flag.png`)"/> INGLÊS</div>
              <div class="center_item"><div class="post_status" :class="{'post_status_published':langIsOk(gameSettings.languages.en)}"></div></div>
            </div>
            <div class="card_item pointer" :class="{item_selected:isThisLanguage(gameSettings.languages.es)}" @click="changeGameLanguage(gameSettings.languages.es)">
              <div class="card_item_name"><img class="flag_item" :src="require(`@/assets/images/spain_flag.png`)"/> ESPANHOL</div>
              <div class="center_item"><div class="post_status" :class="{'post_status_published':langIsOk(gameSettings.languages.es)}"></div></div>
            </div>
          </div>
        </div>

        <div class="cards_game">
          <div class="card_game_header">
            <div class="card_game_name">
              Categorias
            </div>
            <div class="card_game_action" @click="gameSettings.addCategoryVisible = gameSettings.addCategoryVisible ? false: true">
              <b-icon-plus-lg class="card_action_icon"/>
            </div>
          </div>
          <div class="card_add_item" v-if="gameSettings.addCategoryVisible" >
            <div style="width:calc(100% - 10px);">
              <CustomInput :label="'Nome'" v-model="gameSettings.newCategory" :background="'var(--background1)'" :margin="'15px'" :small="true"/>
            </div>
            <div class="card_game_add_action" @click="addCategory()">
              <b-icon-check class="check_icon"/>
            </div>
          </div>
          <div class="card_content custom_scroll" :class="{height_205:!gameSettings.addCategoryVisible}">
            <div class="card_item tree" v-for="item in gameSettings.categories" v-bind:key="item.id">
              <div class="edit_item_action" @click="editCategory(item)"><b-icon-pen/></div>
              <div class="card_item_name">{{item.name}}</div>
              <div class="center_item card_item_action"><CheckBox :value="item.id" :checked="item.checked" @chg="selectCategory"/></div>
            </div>
          </div>
        </div>
        <CustomButton :name="'EXCLUIR'" v-if="gameEdit.id > 0" class="red_color" :active="gameSettings.saveButtonActive" @ontap="openDeleteGameDialog(safeCopyObject(gameEdit))"/>
      </div>
    </div>

    <div class="parts bottom_part">
      <div class="cards_game users_online_card" id="app_uses_chart">
          <UsersStatistics/>
      </div>

      <OnlinePlayingStatus/>

      <SearchesInApp/>

      <GamesReviews/>
    </div>

    <FloatSettings :title="'JOGOS EM DESTAQUE'" :visible="highlightsGamesVisible" @onclose="highlightsGamesVisible = false">
     <HighlightsGames/>
    </FloatSettings>

    <FloatSettings :title="'CONFIGURAÇÕES'" :visible="configsVisible" @onclose="configsVisible = false">
      <div class="settings">
        <CustomButton :name="'CONFIGURAR JOGOS EM DESTAQUE'" @click="highlightsGamesVisible = true; configsVisible = false" />
      </div>
    </FloatSettings>

    <FloatSettings :title="'EDITAR CATEGORIA'" :visible="editCategoryVisible" @onclose="editCategoryVisible = false">
      <div class="edit_category">
        <CustomInput :label="'Nome'" v-model="categoryEdit.name" :background="'var(--configsBC)'" :margin="'15px'"/>
        <CustomButton :name="'SALVAR'" @click="updateCategory()" />
        <CustomButton :name="'EXCLUIR'" @click="deleteCategory()" class="red_color"/>
      </div>
    </FloatSettings>

    <FloatSettings :title="'EXCLUIR GAME'" :visible="deleteGame.visible" @onclose="deleteGame.visible = false">
      <div class="edit_category">
        <span class="span_text">tem certeza que seseja excluir esse game?</span>
        <CustomButton :name="'SIM'" @click="deleteGameConfirmed()" />
        <CustomButton :name="'NÃO'" @click="deleteGame.visible = false" />
      </div>
    </FloatSettings>

  </div>
</template>

<script src="@/controllers/panelController.js"></script>

<style  src="@/styles/panel.css">

</style>