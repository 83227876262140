<template>
  <div class="selector_container">
    <div
      class="option"
      v-for="(item, index) in items" :key="index"
      v-on:click="onClick(index)"
      :class="{ 'active_item': index ==  select}">
      {{ item }} bits
    </div>
  </div>
</template>

<script>
export default {
 mounted() {
  this.select = this.selected
 },
  data(){
    return {
      select: -1,
      focus: false,
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    selected:{
      type: Number,
      required: true
    }
  },
  watch: {
    selected(newVal) {
      this.select = newVal;
    }
  },
  methods:{
    onClick(index){
      this.select = index
      this.$emit('change', this.items[index]);
    }
  },
}
</script>

<style scoped>
  .selector_container{
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 17px;
    width: 100%;
    height: 38px;
    background: var(--background1);
    border-radius: 14px;
    overflow: hidden;
  }
  .option{
    flex: 1;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--textColor);
    font-size: 14px;
    cursor: pointer;
  }
  .active_item{
    background: var(--theme);
    color: var(--textColorDark);
    font-weight: bold;
    font-size: 16px;
    border-radius: 14px;
  }
</style>